body{
    background-color: black;
}

input[type=number]#telefono::-webkit-inner-spin-button, 
input[type=number]#telefono::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/**Componente Reservas**/
.reservas-app{
    background-color: black;
}

.reservas-app .wrapper-titulo{
    padding-top: 10px;
}

.reservas-app .wrapper-titulo h1{
    color: white;
    font-family: Vollkorn, sans-serif;
    font-size: 60px;
    font-weight: 100;
    text-transform: uppercase;
    line-height: 72px;
    letter-spacing: 6px;
    word-spacing: 0;
    text-align: center;
}

.reservas-app .wrapper-descripcion p{
    font-family: Open Sans, sans-serif;
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 16px;
    line-height: 25.6px;
}

.wrapper-reservas{
    width: 50%;
    padding: 41px;
    background: #fdfbfbc2;
    border-radius: 14px;
    margin: 0 35px 0 25%;
}

.reservas h4{
    text-align: center;
}

.reservas button.local{
    width: 50%;
    margin-left: 25%;
    margin-top: 32px;
    background: red;
    color: white;
    padding: 20px 60px;
    cursor: pointer;
}

.reservas button.local:hover,
.reservas .wrapper-horas button:hover,
.reservas .continuar button:hover{
    background: rgba(221, 0, 10);
    color: white;
}

.reservas .wrapper-horas button{
    background: red;
    text-align: center;
    padding: 10px 40px;
    margin-bottom: 8px;
    color: white;
    cursor: pointer;
}

.reservas .continuar button{
    background: #e9ecef;
    width: 100%;
    padding: 10px 40px;
    margin-top: 16px;
    cursor: pointer;
}

.wrapper-paso-image img {
    width: 100%;
}

.wrapper-paso-image{
    margin-bottom: 16px;
}

.wrapper-confirmacion img{
    width: 100%;
    border-radius: 4px;
}

.reservas .bloqueado button{
    color: white;
    font-weight: bold;
    background-image: linear-gradient(180deg, #A03039 0%, #DB1D2C 100%);
    padding: 10px 20px;
    cursor: pointer;
}

.reservas .bloqueado .boton{
    text-align: center;
}

.reservas .bloqueado .img{
    align-self: center;
}

.reservas .bloqueado .wrapper-item{
    display: flex;
    margin-top: 8px;
}

.reservas .bloqueado .wrapper-item img{
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.reservas .bloqueado .reserva{
    margin-top: 32px;
}

.reservas .bloqueado h4{
    text-align: center;
}

.reservas .bloqueado .wrapper-item{
    margin-top: 8px;
}

.reservas .bloqueado .boton button{
    margin-top: 8px;
    margin-bottom: 8px;
}

.confirmacion .datos{
    display: flex;
}

.confirmacion .datos .wrapper-item,
.confirmacion .wrapper-item{
    display: flex;
    width: 100%;
}

.confirmacion .datos img,
.confirmacion .wrapper-item img{
    width: 32px;
    height: 32px;
    margin-right: 20px;
}

@media (max-width: 1199px){/**992px -1199px**/
    .wrapper-reservas {
        width: 60%;
        margin: 35px 35px 35px 20%;
    }

    div#menudevelopers {
        margin-left: 12%;
    }
}

@media (max-width: 991px){/**768 - 991px**/
    .reservas button.local {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 767px){ /**576px - 767px**/
    .wrapper-reservas {
        width: 80%;
        margin: 35px 35px 35px 10%;
    }
}

@media (max-width: 575px) { /**0 - 575px**/
    .reservas-app .wrapper-titulo h1 {
        font-size: 40px;
    }

    .reservas-app .wrapper-descripcion p {
        font-size: 14px;
    }

    .wrapper-horas{
        margin-top: 16px;
    }
    
    .wrapper-reservas {
        padding: 25px;
    }
}

@media (max-width: 400px){
    .reservas-app .wrapper-titulo h1 {
        font-size: 30px;
    }

    .reservas-app .wrapper-descripcion p {
        font-size: 12px;
    }
}